import 'project/general/css/normalize.css';

/* general styles */

import 'project/general/scss/index.scss';

/* project */

import 'project/lazysizes';

/* plugins */

import 'project/general/js/what-input';

/* components */
import 'components/header';
import 'components/form';
import 'components/order-panel';
import 'components/order-info';
import 'components/footer';
import 'components/user-info';
import 'components/nav';
import 'components/modal';
import 'components/filter-group';
import 'components/pagination';
import 'components/status';
import 'components/order-details';
import 'components/tooltip';
import 'components/spinner';
/* require svg */

const files = require.context('./project/general/svg', true, /^\.\/.*\.svg/);

files.keys().forEach(files);

// do not focus sprite in IE

const spriteNode = document.getElementById('__SVG_SPRITE_NODE__');

if (spriteNode) {
    spriteNode.setAttribute('focusable', 'false');

    spriteNode.setAttribute('aria-hidden', 'true');
}
