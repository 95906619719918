import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class Tooltip {
    constructor(el) {
        this.el = el;
        this.init();
    }

    init() {
        tippy(this.el, {
            content: this.el.getAttribute(['data-tooltip-content'])
        });
    }
}
